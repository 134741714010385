<template>
  <v-theme-provider>
    <base-section
      id="faq"
      class="primary lighten-4"
      space="36"
    >
      <base-section-heading
        title="Perguntas mais comuns"
        icon="mdi-comment-question"
      />
      <v-container>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            v-for="([question, answer], i) in questions"
            :key="i"
            cols="12"
            md="6"
          >
            <v-card>
              <v-card-title
                color="black"
                class="blue lighten-4"
              >
                <v-icon
                  size="42"
                  class="mr-3"
                >
                  mdi-comment-question-outline
                </v-icon>
                {{ question }}
              </v-card-title>
              <v-card-text
                class="pt-4 body-1 text-justify font-weight-medium"
                v-html="answer"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionFaq',

    data: () => ({
      questions: [
        [
          'Qual é a garantia de que o serviço funciona?',
          'É de satisfação <b>TOTAL!</b> Todo seu plano alimentar é combinado antes de ser entregue, ou seja, você saberá quais alimentos e suplementos vão fazer parte da sua alimentação e pra qual objetivo ele é feito, caso não goste ou ache que não está de acordo com sua disponibilidade eu devolvo todo seu dinheiro em no máximo um dia útil.',
        ],
        [
          'Quem pode ter um acompanhamento nutricional?',
          'Toda e qualquer pessoa, indiferente de ser criança, adulto ou idoso, de qualquer peso, altura, se pratica ou não exercício físico, se tem alguma doença ou é saudável e mesmo quem tem restrições alimentares (por exemplo, não come carne, intolerante a lactose, etc.). O meu <b>ÚNICO</b> requisito é que você queira evoluir!',
        ],
        [
          'Tem contrato ou multa por cancelamento?',
          'Não, nada! Você pode parar ou suspender com o acompanhamento à qualquer momento sem precisar justificar ou pagar taxas por isso. Penso que um período de fidelidade é inútil se o trabalho for bom, porque você irá querer permanecer pelos benefícios que tem e não pela força de um contrato.',
        ],
        [
          'Já tentei acompanhamento nutricional antes, por que tentar a Nutrição Moderna?',
          'Muitos profissionais usam uma linha de nutrição que tenta fazer VOCÊ e sua rotina se moldarem ao plano alimentar, a nutrição moderna faz justamente o contrário: é a <b>ALIMENTAÇÃO</b> que se molda em torno de você, ao ter alimentos nos horários que você se sente confortável em comer, com alimentos que já fazem parte do seu dia-a-dia, práticos e saborosos pra você.',
        ],
        [
          'Preciso praticar exercício físico pra ter bons resultados?',
          'A alimentação é uma área independente, ela não depende de outras atividades pra lhe trazer bons resultados, é verdade que com exercício físico os resultados podem ser melhores e mais rápidos, mas a falta deles não impede de começar a melhorar sua vida.',
        ],
      ],
    }),
  }
</script>
<style>
  .v-card__title {
    word-break: break-word;
    line-height: 1.5rem;
  }
</style>
